/* eslint-disable max-len,react/no-danger */
import React from "react";
import { PageHeader, Seo, Footer } from "components/common";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "components/commonStyles";
import { siteUrl } from "configs/constants";
import { Link } from "gatsby";

function JsonRestApi({ classes }) {
  return (
    <>
      <Seo
        title="JSON-REST API Guidelines"
        url={`${siteUrl}/json-rest-api-guidelines`}
      />
      <PageHeader />
      <div className={classes.content}>
        <p style={{ marginBottom: 20 }}>
          Explains urls, HTTP headers, status codes, way of returning error
          codes, caching and data consistancy.
        </p>
        <p style={{ marginBottom: 20 }}>
          Before reading this article we recommend you to read{" "}
          <Link to="/developers/data-domain/">
            <span className={classes.textColor}>Terms</span>
          </Link>
          ,{" "}
          <Link to="/developers/api-key/">
            <span className={classes.textColor}>Security</span>
          </Link>{" "}
          and{" "}
          <span className={classes.textColor}>
            <Link to="/developers/error-handling/">Error handling</Link>
          </span>
          .
        </p>
        <p style={{ marginBottom: 10 }}>
          The background protocol is regular HTTP. Follow regular{" "}
          <a href="https://en.wikipedia.org/wiki/Representational_state_transfer">
            <span className={classes.textColor}>REST</span>
          </a>{" "}
          convention to understand API architecture. Here is a few important
          points you must know:
        </p>
        <ul style={{ marginBottom: 60 }}>
          <li>
            Response body is returned according to{" "}
            <a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.1">
              <span className={classes.textColor}>Accept</span>
            </a>{" "}
            header when no error occurs. It should be “application/json” for
            JSON and “application/javascript” for JSONP.
          </li>
          <li>
            List of all available url’s for data exchange with description and
            code samples can be found at JSON-REST Reference .
          </li>
          <li>
            And don’t forget to declare{" "}
            <a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.17">
              <span className={classes.textColor}>Content-Type</span>
            </a>
            : application/json when sending data to the server.
          </li>
        </ul>
        <h4>Url naming conventions</h4>
        <p style={{ marginBottom: 20 }}>General format of every url is</p>
        <p style={{ marginBottom: 20 }}>
          https://
          <span
            className={classes.textHead}
            style={{ wordBreak: "break-word" }}
          >
            servername.com/vversion/collection-name[options]
          </span>
        </p>
        <p style={{ marginBottom: 20 }}>where</p>
        <ul style={{ marginBottom: 60 }}>
          <li>
            <span className={classes.textHead}>servername.com</span> will be
            provided to you by support team
          </li>
          <li>
            <span className={classes.textHead}>version</span> is a number of
            current api version, you can find this number by visiting
            https://servername.com/api/version, and get all the info
            (documentation, examples and libraries) about it at
            http://srv4pos.com/v
            <span className={classes.textHead}>version</span>. For instance if
            version is 15, then the url will look like
            https://servername.com/v15/collection-name[/options], the info about
            it is reacheable at http://srv4pos.com/v15
          </li>
          <li>
            <span className={classes.textHead}>collection-name</span> is the
            name of entity, for instance all products can be found by url
            https://servername.com/v15/products
          </li>
          <li>
            <span className={classes.textHead}>options</span> might be
            <ul>
              <li>
                <span className={classes.textHead}>/identifier</span>
                <br />
                to reach particular entity. for instance to reach product with
                identifier “0-1A9E” you use
                https://servername.com/v15/products/0-1A9E
              </li>
              <li>
                -diff
                <span className={classes.textHead}>/from/to</span>
                <br />
                to discover, what has been changed on the server. for instance,
                if you want to know which products have been deleted, created or
                modified between 100 and 200 you use
                https://servername.com/v15/products-diff/100/200 . If you are
                interested what is 100 and 200 – read{" "}
                <span className={classes.textColor}>
                  <Link to="/developers/sync/">Synchronization and Caching</Link>
                </span>
              </li>
              <li>
                /<span className={classes.textHead}>identifier</span>
                /images
                <br />
                to see which images are attached to a particular entity. for
                instance https://servername.com/v15/products/0-1A9E/images will
                show list of all images for product with id 0-1A9E
              </li>
              <li>
                ?firstResult=
                <span className={classes.textHead}>0</span>
                &maxResults=
                <span className={classes.textHead}>30</span>
                &orderBy=
                <span className={classes.textHead}>fieldName</span>
                &orderDesc=
                <span className={classes.textHead}>false</span>
                &like=
                <span className={classes.textHead}>substring</span> to be able
                to narrow output results. Each parameter is optional. Some
                parameters might not work under some urls. There can be some
                more parameters
              </li>
            </ul>
          </li>
        </ul>
        <p style={{ marginBottom: 20 }}>
          This is just a convention, and some urls don’t follow it.
        </p>
        <p style={{ marginBottom: 10 }}>Other notes:</p>
        <ul style={{ marginBottom: 60 }}>
          <li>
            Words are separated with hyphens (‘-‘) and lower case: https://
            <span className={classes.textHead}>servername.com</span>
            /v15/control-units
          </li>
          <li>
            GET, DELETE, PUT http methods are allowed for https://
            <span className={classes.textHead}>
              servername.com/vversion/collection-name/identifier
            </span>
          </li>
          <li>
            POST to https://
            <span className={classes.textHead}>
              servername.com/vversion/collection-name
            </span>{" "}
            creates an entity
          </li>
          <li>
            This convention was taken from
            <br />
            <span className={classes.textColor}>
              <a href="http://www.restapitutorial.com/lessons/restfulresourcenaming.html">
                http://www.restapitutorial.com/lessons/restfulresourcenaming.html
              </a>
            </span>
          </li>
        </ul>
        <h4>Authentication</h4>
        <p style={{ marginBottom: 10 }}>
          Most of the URLs requires authentication.{" "}
          <a href="https://en.wikipedia.org/wiki/Basic_access_authentication">
            <span className={classes.textColor}>Basic authentication</span>
          </a>{" "}
          is used. Use “Authorization” header. Value is word “Basic ” and base64
          encoded string. The string depends on your role:
        </p>
        <ul style={{ marginBottom: 60 }}>
          <li>
            For role ACTIVATION the string is:
            <br />
            {"{sellerId}/{productionNumber}:{activationSecurityToken}"}
          </li>
          <li>
            For role SELLER_ADMIN the string is:
            <br />
            {"{username}@{sellerId}:{password}"}
          </li>
        </ul>
        <h4>Error handling</h4>
        <p style={{ marginBottom: 20 }}>For simple json exceptions:</p>
        <p style={{ marginBottom: 20 }}>
          {'{"error":"SomeSimpleJsonException"}'}
        </p>
        <p style={{ marginBottom: 20 }}>
          {'1 {"error":"SomeSimpleJsonException"}'}
        </p>
        <p style={{ marginBottom: 20 }}>For detailed json exceptions:</p>
        <p style={{ marginBottom: 20 }}>
          {
            '{ "details":{ "identifier":"ID-1", "entityName":"MyEntity" }, "error":"SomeDetailedJsonException" }'
          }
        </p>
        <p style={{ marginBottom: 20 }}>
          {
            '1 2 3 4 5 6 7 { "details":{ "identifier":"ID-1", "entityName":"MyEntity" }, "error":"SomeDetailedJsonException" }'
          }
        </p>
        <p style={{ marginBottom: 20 }}>
          For special ValueNotValidJsonException:
        </p>
        <p style={{ marginBottom: 20 }}>
          {
            '{ "details":[ { "code":"SPECIFIC_ERROR_CODE", "field":"name", "message":"Some message.", "params":{ "actualValue":"invalidValue", "someParam1":"1", "someParam2":"32" } } ], "error":"ValueNotValidJsonException" }'
          }
        </p>
        <p style={{ marginBottom: 20 }}>
          {
            '1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 { "details":[ { "code":"SPECIFIC_ERROR_CODE", "field":"name", "message":"Some message.", "params":{ "actualValue":"invalidValue", "someParam1":"1", "someParam2":"32" } } ], "error":"ValueNotValidJsonException" }'
          }
        </p>
        <p style={{ marginBottom: 20 }}>
          For UnprocessableJsonException, InconsistentDataJsonException and
          ForbiddenJsonException:
        </p>
        <p style={{ marginBottom: 20 }}>
          {
            '{ "code":"SPECIFIC_ERROR_CODE", "details":{ "message":"Some message" }, "error":"SomeSpecialJsonException" }'
          }
        </p>
        <p style={{ marginBottom: 20 }}>
          {
            '1 2 3 4 5 6 7 { "code":"SPECIFIC_ERROR_CODE", "details":{ "message":"Some message" }, "error":"SomeSpecialJsonException" }'
          }
        </p>
        <p style={{ marginBottom: 20 }}>
          The field “error” contains name of json exception.
        </p>
        <p style={{ marginBottom: 20 }}>
          The field “code” contains{" "}
          <span className={classes.textHead}>specific</span> code of error. It
          can be used for localization and so on.
        </p>
        <p style={{ marginBottom: 60 }}>
          List of all JsonExceptions and types of error (codes) presented in the{" "}
          here.
        </p>
        <h4>Caching</h4>
        <p style={{ marginBottom: 60 }}>
          Some GET methods support{" "}
          <a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.19">
            <span className={classes.textColor}>ETag</span>
          </a>{" "}
          header. When returning data they return seller version inside ETag.
          Seller version and principles are described at{" "}
          <span className={classes.textColor}>
            <Link to="/developers/sync/">Synchronization and Caching</Link>
          </span>{" "}
          page. Later you can provide{" "}
          <a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.24">
            <span className={classes.textColor}>If-Match</span>
          </a>{" "}
          to the methods so they may return HTTP status code 304 and no body,
          Which means the data was not modified since the seller version
          specified in ETag.
        </p>
        <h4>Date & time</h4>
        <p style={{ marginBottom: 60 }}>All data & time is always in UTC.</p>
        <h4>Data consistancy</h4>
        <p>
          If your role is ACTIVATION then POST, PUT, DELETE methods require you
          to provide latest seller version (to make sure the version is actually
          latest). Provide it in{" "}
          <a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.24">
            <span className={classes.textColor}>If-Match</span>
          </a>{" "}
          header. If you want to know more about Data consistancy, read{" "}
          <span className={classes.textColor}>
            <Link to="/developers/sync/">Synchronization and Caching</Link>
          </span>{" "}
          page.
        </p>
      </div>
      <Footer />
    </>
  );
}

JsonRestApi.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(styles, { withTheme: true })(JsonRestApi);
